// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacto-ventas-js": () => import("./../src/pages/contacto-ventas.js" /* webpackChunkName: "component---src-pages-contacto-ventas-js" */),
  "component---src-pages-espiral-meiquer-js": () => import("./../src/pages/espiral-meiquer.js" /* webpackChunkName: "component---src-pages-espiral-meiquer-js" */),
  "component---src-pages-estaciones-juego-js": () => import("./../src/pages/estaciones-juego.js" /* webpackChunkName: "component---src-pages-estaciones-juego-js" */),
  "component---src-pages-experiencias-meiquer-js": () => import("./../src/pages/experiencias-meiquer.js" /* webpackChunkName: "component---src-pages-experiencias-meiquer-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mamas-y-papas-js": () => import("./../src/pages/mamas-y-papas.js" /* webpackChunkName: "component---src-pages-mamas-y-papas-js" */),
  "component---src-pages-meiquer-tv-js": () => import("./../src/pages/meiquer-tv.js" /* webpackChunkName: "component---src-pages-meiquer-tv-js" */),
  "component---src-pages-nosotros-areas-trabajo-js": () => import("./../src/pages/nosotros/areas-trabajo.js" /* webpackChunkName: "component---src-pages-nosotros-areas-trabajo-js" */),
  "component---src-pages-nosotros-index-js": () => import("./../src/pages/nosotros/index.js" /* webpackChunkName: "component---src-pages-nosotros-index-js" */),
  "component---src-pages-servicios-profesionales-fonnor-js": () => import("./../src/pages/servicios-profesionales/fonnor.js" /* webpackChunkName: "component---src-pages-servicios-profesionales-fonnor-js" */),
  "component---src-pages-servicios-profesionales-index-js": () => import("./../src/pages/servicios-profesionales/index.js" /* webpackChunkName: "component---src-pages-servicios-profesionales-index-js" */),
  "component---src-pages-servicios-profesionales-meiquer-labs-historias-js": () => import("./../src/pages/servicios-profesionales/meiquer-labs-historias.js" /* webpackChunkName: "component---src-pages-servicios-profesionales-meiquer-labs-historias-js" */),
  "component---src-pages-servicios-profesionales-reto-kids-js": () => import("./../src/pages/servicios-profesionales/reto-kids.js" /* webpackChunkName: "component---src-pages-servicios-profesionales-reto-kids-js" */)
}

